@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";

.skills-pictures {
  filter: grayscale(100%);
}

.skills-tools {
  li {
    @extend .sans;
    color: $white;
    font-size: .875em;
    padding: .2em .8em;
    border: 1px solid $white;
    display: inline-block;

    &:not(:last-child) {
      margin-right: .75em;
    }
  }
}