@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";

#landing {
  h1#landing-intro {
    color: $white;
    font-size: 2em;
    letter-spacing: .25em;
    line-height: 2em;

    &::before {
      @media screen and (min-width: $break-landscape-phone) {
        content: "I'm ";
      }
    }

    &::after {
      content: " Ho";
    }

  }

  #my-title {
    letter-spacing: .2em;
    font-size: .8em;
    @extend .sans;

    @media screen and (max-width: $break-landscape-phone) {
      letter-spacing: .1em;
    }
  }

  #my-cv {
    display: table;
    margin: 0 auto;
    font-size: .65em;
    font-weight: 300;
    letter-spacing: .5em;
    border-color: transparentize($white, .5);
    border-style: solid;
    border-width: 1px 0;
    @extend .serif;
    letter-spacing: .2em;

  }

  top: -5em;
  height: calc(100vh - 5em);

}