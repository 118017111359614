@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";
@import "../../sass/links";

footer {
  padding: .5em 0;

  p {
    @extend .serif;
    font-size: .75em;
    color: $white;
  }

  a {
    color: $white;

    &:hover {
      color: $white;
      @extend .turquoise-links;
    }
  }

  @media screen and (max-width: $break-portrait-tablet) {
    background-color: transparentize($off-black, .5);
  }

  &.page-footer {
    background-color: $turquoise;
    min-height: 50vh;
    h1{
      -webkit-text-stroke: .025em $white;
      -webkit-text-fill-color: $turquoise;
    }
  }
}