@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";
@import "../../sass/links";

.webp .process-photostrip {
  .process-photostrip-background {
    background: $black url(banner@1x.webp);
    background-position: 20% 50%;
    background-size: cover;
  }
}

.no-webp .process-photostrip {
  .process-photostrip-background {
    background: $black url(banner@1x.jpg);
    background-position: 20% 50%;
    background-size: cover;
  }
}

.process-photostrip {
  .process-photostrip-background {
    width: 100vw;
    height: 35vw;
    min-height: 220px;
  }

  .uk-overlay-primary {
    background: unset;
    background-image: linear-gradient(to right, transparentize($off-black, 0.2), transparentize($off-black, .5), transparentize($off-black, .1));

    @media screen and (max-width: $break-landscape-phone) {
      background-image: linear-gradient(to right, transparentize($off-black, 0.25), transparentize($off-black, 0));
    }
  }

  h2 {
    text-shadow: 0 0 .5em $black;
  }
}

#process, #flow {
  background-image: linear-gradient(transparent, transparentize($off-black, .5), transparentize($off-black, .25), transparentize($off-black, .25), transparentize($off-black, .5), transparent);
  min-height: unset;
  padding-bottom: 15vh;

  > div {
    @media screen and (max-width: $break-landscape-phone) {
      margin-left: 0;
    }
  }

  .section-title {
    padding-top: 2em;
    padding-bottom: 2em;

    @media screen and (max-width: $break-landscape-phone) {
      padding-left: 1em;
      padding-right: 1em;
    }

    .profile-title {
      font-size: .7em;
      letter-spacing: .3em;
      color: $white_75;
      display: inline-block;
      position: relative;
    }

    p {
      color: $white_50;
      font-size: .8em;
    }
  }

}