@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";
@import "../../sass/links";

#works {
  p {
    color: $off-black;
  }

  #lastbutton {
    p {
      padding-top: 5vh;
      padding-bottom: 15vh;
    }
  }

  #filter-control {
    .uk-subnav-pill {
      a {
        @extend .sans;
        background: none;
        border-bottom: .2em solid transparent;
        font-size: .8em;
        letter-spacing: .2em;

        &:hover {
          color: $moderate-gray;
          border: none;
        }
      }
      .uk-active {
        > a {
          background: none;
          border-bottom: .2em solid $white;
        }
      }
      span {
        @extend .sans;
        color: $light-gray;
        font-size: .875rem;
        letter-spacing: .2em;
        cursor: default;
      }
    }
  }
  ul {
    &#filter-items {
      > li {
        > a {
          div {
            filter: grayscale(100%);
            opacity: .75;
            transition: 150ms filter ease-in, 200ms opacity ease-in;
            &:hover {
              filter: grayscale(0%);
              opacity: 1;
            }
          }
        }
        div {
          p {
            @extend .sans;
            overflow: hidden;
            color: $white;
            font-size: .8em;
            padding: .5em 1em;
            background-image: linear-gradient(transparent, transparentize($black, .3), $black);
          }
        }
      }
    }
  }
}

.portfolio-modal {
  background-color: $white!important;
  h1, h2, h3, h4, h5, h6, p, dt, dd {
    color: $black;
  }
}