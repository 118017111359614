div {
  &.vert-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

html {
  background: none;
}

.webp body {
  // background-image: url(../img/meWork.webp);
}

.no-webp body {
  // background-image: url(../img/meWork.jpg);
}

body {
  background-color: $black;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  min-height: 100vh;

  @media screen and (max-width: $break-landscape-tablet) {
    background-image: none;
    background-color: $black;
  }
}

section {
  padding-top: 15vh;
  min-height: 100vh;

  a {
    color: $turquoise;

    &:hover {
      @extend .turquoise-links;
    }
  }

  > img {
    max-height: 100px;
  }

  > h1 {
    margin-top: 0.5em;
  }

  > h5 {
    color: $off-white;

    span {
      color: $off-white;
    }
  }
}

.uk-modal-full {
  button {
    background-color: transparent;
  }

  div {
    .iframe {
      width: 100%;
      overflow: scroll;

      @media screen and (max-width: $break-small) {
        margin: 0;

        > div {
          padding-left: 0;
          padding-right: 0;

          dl {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }

    .iframe-cover {
      background: transparentize($white, 0.2);

      dl {
        background: transparentize($white, 0.1);
        box-shadow: 0 0 2em 2em transparentize($white, 0.25);
        transition: width 0.3s ease-in;

        dt {
          font-weight: 700;
        }

        dd {
          &.works-def {
            border-bottom: 1px solid $gray;
            font-size: 0.7em;
          }
        }
      }

      @media screen and (min-width: $break-medium) {
        background: linear-gradient(
          to right,
          $white,
          transparentize($white, 0.2)
        );
        dl {
          width: 50%;
        }
      }
    }

    .modal-text {
      @media screen and (min-width: $break-small) {
        max-height: 100vh;
        overflow: scroll;
      }
    }

    .iframe-slideshow {
      @media screen and (min-width: $break-small) {
        height: 100vh;
      }

      .uk-slideshow-items {
        li {
          img {
            padding: 0.5em;
            @media screen and (min-width: $break-medium) {
              padding: unset;
            }
          }
        }

        dt {
          padding-bottom: 0.5em;

          span {
            display: block;
          }

          a {
            top: -0.5em;
            position: relative;
            font-weight: 400;
            font-size: 0.75em;
            color: $moderate-gray;

            &:hover {
              color: $white;
              @extend .turquoise-links;
            }
          }
        }

        dd {
          font-size: 0.75em;
        }
      }

      .uk-thumbnav {
        margin-left: auto;
        margin-right: auto;

        img {
          width: 3em;
        }
      }
    }
  }
}

.uk-button {
  letter-spacing: 0.05em;
  color: darken($turquoise, 15%);
  border: 1px solid darken($turquoise, 15%);

  &:hover {
    @extend .hovered;
  }

  &:active {
    color: $turquoise;
    border: 1px solid $turquoise;
  }
}

.hovered {
  border: 1px solid $turquoise;
  background-color: $turquoise;
  color: $white;

  &:hover {
    background-color: darken($turquoise, 25%);
  }
}

.bg-white {
  background-color: $white;

  h1,
  p {
    color: $off-black;
  }
}

.bg-black {
  background-color: $black;

  h1,
  p {
    color: $white;
  }
}

.uk-tooltip-inner {
  @extend .serif;
}

.profile-headers {
  font-size: 0.7em;
  letter-spacing: 0.3em;
  color: $white_75;
  display: inline-block;
  position: relative;
}

#post-banner {
  @extend .serif;
  background-color: transparentize($light-green, 0.05);
  border-bottom: 0.1em solid $green;
  color: $green;
}
