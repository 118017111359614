@import "../../sass/colors";
@import "../../sass/typography";

#contact {
  min-height: calc(100vh - 3em);

  label {
    @extend .serif;
    color: $moderate-gray;
  }

  input, textarea {
    @extend .serif;
    color: $white;
    background-color: transparent;
    border-right: none;
    border-left: none;
    border-top: none;
    &:focus {
      border-color: $turquoise;
    }
  }

  textarea {
    resize: vertical;
    overflow: auto;
    min-height: 2.5em;
  }

  button {
    color: $white;
    letter-spacing: .2em;
    height: 3em;
    transition: border-color .3s linear;
    border-radius: 0;
    border-color: $white;
    &:focus {
      border-color: $turquoise;
    }
    &:hover {
      border-color: $moderate-gray;
      background: none;
    }
  }
  .contact-icon {
    width: 1.5em;
    text-align: right;
    display: inline-block;
    margin-right: .2em;
  }
  #contact-links {
    span {
      color: $white;
      transition: color .3s linear;
      &:hover {
        color: $turquoise;
      }
    }
  }
}