@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";
@import "../../sass/links";

.webp #skills {
  #research {
    background-image: url(./skills_research@1x.webp);
  }

  #communicate {
    background-image: image-set(
      url(./skills_communicate@1x.webp) 1x,
      url(./skills_communicate@2x.webp) 2x
    );
  }

  #concepts {
    background-image: image-set(
      url(./skills_conceptualize@1x.webp) 1x,
      url(./skills_conceptualize@2x.webp) 2x
    );
  }

  #solutioning {
    background-image: url(./skills_solutioning@1x.webp);
  }

  #develop {
    background-image: url(./skills_develop@1x.webp);
  }

  #workshops {
    background-image: image-set(
      url(./skills_workshop@1x.webp) 1x,
      url(./skills_workshop@2x.webp) 2x
    );
  }
}

.no-webp #skills {
  #research {
    background-image: url(./skills_research@1x.png);
  }

  #communicate {
    background-image: image-set(
      url(./skills_communicate@1x.jpg) 1x,
      url(./skills_communicate@2x.jpg) 2x
    );
  }

  #concepts {
    background-image: image-set(
      url(./skills_conceptualize@1x.jpg) 1x,
      url(./skills_conceptualize@2x.jpg) 2x
    );
  }

  #solutioning {
    background-image: url(./skills_solutioning@1x.png);
  }

  #develop {
    background-image: url(./skills_develop@1x.png);
  }

  #workshops {
    background-image: image-set(
      url(./skills_workshop@1x.jpg) 1x,
      url(./skills_workshop@2x.jpg) 2x
    );
  }
}

#skills {
  .cursor-unset {
    cursor: unset;
  }

  a {
    &.uk-position-cover {
      //cursor: default;
    }
  }

  .uk-overlay-primary {
    transition: opacity 0.3s;
    opacity: 0.75;
  }

  .uk-overlay {
    padding-left: 0;
    padding-right: 0;

    > h4 {
      transition: transform 0.3s;
      //opacity: 0;
      transform: translateY(150%);
      background-color: transparentize($black, 0.15);
      padding: 1em 0;
    }

    > p {
      transition: transform 0.3s;
      opacity: 0;
      transform: translateY(125%);
    }
  }

  .uk-cover-container {
    transition: all 0.3s;

    &:hover > .uk-overlay-primary {
      opacity: 1;
    }

    &:hover > .uk-overlay > h4 {
      opacity: 1;
      transform: translateY(0);
      transform: scale(1.2);
      background-color: transparent;
      padding: 0;
    }

    &:hover > .uk-overlay > p {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .skills-image {
    background: $black no-repeat center;
    background-size: 120%;

    &:hover {
      background-size: 100%;
    }
  }

  p {
    @extend .serif;
    color: $white;
  }

  .uk-tile {
    height: 18em;
  }

  .uk-cover-container {
    p {
      font-size: 0.8em;
    }
  }

  a {
    &.uk-position-cover {
      &:hover {
        border-bottom: none;
      }
    }
  }
}
