@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";
@import "../../sass/links";

#profile {
  min-height: unset;
  padding-bottom: 15vh;

  .profile-title {
    font-size: .7em;
    letter-spacing: .3em;
    color: $white_75;
    display: inline-block;
    position: relative;
  }

  .section-title {
    padding-top: 2em;
    padding-bottom: 2em;

    @media screen and (max-width: $break-landscape-phone) {
      padding-left: 0;
      padding-right: 0;
    }

    > div {
      &:first-child {
        > div {

          @media screen and (max-width: $break-medium) {

            &::before {
              content: "";
              position: absolute;
              height: 1px;
              background-color: transparentize($white, .3);
              width: 20px;
              left: -30px;
              top: 50%;
            }

            &::after {
              content: "";
              position: absolute;
              height: 1px;
              background-color: transparentize($white, .3);
              width: 20px;
              right: -30px;
              top: 50%;
            }
          }
        }
      }
    }
  }

  .avatar {
    width: 6em;
    height: 6em;
  }

  div {
    span {
      font-size: .75em;
      letter-spacing: 0.2em;
    }
  }

  #middle {
    #brief {
      width: calc(100% - 7em);
    }
    blockquote {
      font-size: .85em;

      @media screen and (max-width: $break-landscape-phone) {
        width: calc(100% - 7em);
      }

    }
  }

  #social-icons {

    ul {
      margin: 0;
      li {
        display: inline-block;
        margin: .26em;
        font-size: 1.1em;

        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        @media screen and (min-width: $break-landscape-phone) {
          margin: .5em;
          font-size: 1.5em;
        }
      }
    }

    a {
      background-color: transparent;
      color: $moderate-gray;
      transition: color .3s ease-in-out;

      &:hover {
        border: 0;
        color: $white;
      }

    }
  }

}