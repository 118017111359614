// Autodesk Colors
$autodesk-blue: rgba(6, 150, 215, 1);
$autodesk-white: rgba(255, 255, 255, 1);
$charcoal-gray: rgba(60, 60, 60, 1);
$autodesk-black: rgba(0, 0, 0, 1);
$green: #155624;
$turquoise: rgba(50, 188, 173, 1);
$dark-blue: rgba(24, 88, 168, 1);
$golden-yellow: rgba(255, 170, 17, 1);
$red: rgba(221, 34, 34, 1);
$dark-fuchsia: rgba(167, 0, 99, 1);
// Custom Colors
$cyan: #099;
$hovered-blue: #2481af;
$active-blue: #3071a9;
$active-darkblue: #285e8e;
$hovered-blueshade: rgba(36, 129, 175, .1);
$focused-darkblue: #08648f;
$no-fill: transparent;
$gold: #bdb099;
$burlywood: #deb887;
$rosybrown: #bc8f8f;
$light-green: #d4edda;
//Thumbnail Colors
$clear-thumb-blue: rgba(0, 32, 80, 0);
$opaque-thumb-blue: rgba(0, 32, 80, 1);
//alanho Colors
$shadow-black: rgba(0, 0, 0, .55);
$dark-gray: #666;
$gray: #777;
$moderate-gray: #ccc;
$light-gray: #eee;
$black: #121212;
$jet-black: #000;
$white_100: rgba(255, 255, 255, 1);
$white_75: rgba(255, 255, 255, .75);
$white_50: rgba(255, 255, 255, .50);
$white_25: rgba(255, 255, 255, .25);
$white_10: rgba(255, 255, 255, .10);
$white_5: rgba(255, 255, 255, .05);
$white: $white_100;
$off-white: $white_50;
$off-black: #222;
$soft-red: rgba(221, 34, 34, .05);

//Social Providers Colors

$linkedin: #007bb6;
$twitter: #00aced;
$pocket: #ed4055;
$behance: #0b6dff;
$angellist: #000;
