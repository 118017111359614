.visible {
  display: none;
}

.hidden {
  display: none;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

}