@import url('https://fonts.googleapis.com/css?family=Lato:400|Raleway:400');
@import "./widths";

.sans {
  font-family: 'Lato', Helvetica, sans-serif;
}

.serif {
  font-family: 'Raleway', Courier, serif;
  line-height: 1.8;
  letter-spacing: .05em;
}

h1, h2, h3, h4, h5, h6, dt {
  color: $white;
  @extend .sans;
  letter-spacing: .1em;
}

p, dd, li {
  color: $white;
  @extend .serif;
}

.skills-pages {

  @media screen and (min-width: $break-medium) {
    h2 {
      font-size: 5em;
    }

    h3 {
      font-size: 3em;
    }
  }

  h6 {
    font-size: .8em;
  }
}