@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";
@import "../../sass/links";

#resume {
  .uk-card-default {
    transition: background-color .5s ease-in-out;
    background: none;
    box-shadow: none;
    border: 1px solid $light-gray;

    @media screen and (min-width: $break-medium) {
      min-width: 20em;
    }

  }
  #cv-online {
    > div {
      &:hover {
        background-color: transparentize($turquoise, .25);
      }
    }

    .uk-card-default {
      @media screen and (max-width: $break-landscape-phone) {
        margin-bottom: 2em;
      }
    }
  }
  #cv-offline {
    > div {
      &:hover {
        background-color: transparentize($turquoise, .25);
      }
    }
  }

  @media screen and (max-width: $break-landscape-phone) {
    .uk-comment-meta {
      font-size: .7em;
    }
    .uk-tile {
      padding: 0;
    }
  }

  article {
    color: $light-gray;
    p {
      color: $light-gray;
    }
    span {
      color: $light-gray;
      text-transform: lowercase;
    }
  }

}

#modal-resume {

  .uk-modal-dialog {
    box-shadow: 0px 0px 6em $light-gray;

    .uk-modal-body {
      iframe {
        width: 100%;
        min-height: 100vh;
      }
    }

    .uk-modal-footer {
      background-color: $off-black;
    }

  }

}