@import "../sass/colors";
@import "../sass/typography";
@import "../sass/links";

button {
  &.button-link {
    color: $turquoise !important;
    background: none;
    border: none;
    height: 2em !important;
    padding: unset;
    letter-spacing: unset!important;
    &:hover {
      cursor: pointer;
      @extend .turquoise-links;
    }
  }
}
