@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";

header {
  position: sticky;

  .uk-navbar-container {
    padding-bottom: 1em;
    padding-right: 1em;

    background: linear-gradient(to right, transparent, transparentize($off-black, .5), transparentize($off-black, .1));

    @media screen and (max-width: $break-medium) {
      background: linear-gradient(to left, transparent, transparentize($off-black, .5), transparentize($off-black, .1));
    }

    li {
      a {
        @extend .sans;
        height: 2.5em;
        margin-top: 1em;
        min-height: unset;
        letter-spacing: .1em;
      }

      &.uk-active {
        border-bottom: .2em solid $white;

        a {
          font-weight: bold;
        }
      }
    }
  }
}

.page-header {
  .no-decoration {
    &:hover {
      border: none !important;
    }
  }
}

#offcanvas {
  li {
    a {
      @extend .sans;
    }
  }
}