@import "../../sass/colors";

.progress-bar {

  p {
    font-size: .7em;
    letter-spacing: .1em;
    margin-bottom: .5em;
    &.progress-label {
      font-size: .6em;
      letter-spacing: 0px;
      color: $dark-gray;
      top: -2.6em;
      left: calc(50% - 10px);
    }
  }

  progress {
    height: 1.2em;
    background: $gray;
    color: $moderate-gray;

    &::-moz-progress-bar {
      background: $light-gray;
    }

    &::-webkit-progress-value {
      background: $moderate-gray;
      border-radius: 0 .6em .6em 0;
    }

    &::-webkit-progress-bar {
      background: $gray;
    }
  }

}

