@import "../../sass/colors";
@import "../../sass/widths";
@import "../../sass/typography";

.webp #page-solutioning {
  .page-banner {
    background-image: url(solutioning-banner@1x.webp);
  }
}

.no-webp #page-solutioning {
  .page-banner {
    background-image: url(solutioning-banner@1x.jpg);
  }
}

#page-solutioning {
  .page-banner {
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
    filter: grayscale(100%);
  }

  a {
    color: $turquoise;
    text-decoration: none;

    &.pill-link{
      &:hover{
        border: none;
      }
    }

    &:hover {
      text-decoration: none;
      border-bottom: 1px dashed $turquoise;
    }
  }
}

